import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import { Link, graphql } from "gatsby"

export default ({ data }) => {
  const post = data.markdownRemark
  const { title, featuredImage, author, date } = post.frontmatter
  const { slug } = post.fields
  const img = featuredImage
    ? { backgroundImage: `url(${featuredImage.childImageSharp.fluid.src})` }
    : {}
  return (
    <Layout>
      <Helmet title={`vokabox.com - ${title}`} />
      <div className={"page-header backgroundImg"} style={img}>
        <div className={"bgopacity"}></div>
        <div className={"container"} style={{ position: "relative" }}>
          <h1>
            <span className={"Highlight--highlight_wrapper"}>
              <span className={"underline-sprkr cap-all-first"}>{title}</span>
              <span className={"Highlight--highlight_color"}></span>
            </span>
          </h1>
          <ol>
            <Link to={"/"}>Home</Link> / <Link to={"blog"}>Blog</Link> /{" "}
            <Link to={slug} className={"cap-all-first"}>
              {title}
            </Link>
          </ol>
        </div>
      </div>
      <div className={"container blog-container"}>
        <article className={"blog-article"}>
          <h2 className={"entry-title"}>
            <Link to={slug}>{title}</Link>
            {/* ({node.timeToRead} min) */}
          </h2>
          <div className={"entry-info"}>
            <address>
              by {/* <Link to={slug}>{author name}</Link> */}
              {author} {" / "}
              {date}
            </address>
          </div>
          <div className={"entry-content"}>
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
          </div>
        </article>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
        author
        featuredImage {
          childImageSharp {
            resize(width: 1500, height: 1500) {
              src
            }
            fluid(maxWidth: 786) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      fields {
        slug
      }
    }
  }
`
